import AppMutionsType from "@/types/AppMutations";
import CookiesToken from "@/types/CookiesToken";
import {
  getBrowserLanguage,
  getCookieValue,
  getDeviceOrientation,
  isIosMobile,
  isMobile,
  setCookieValue,
} from "@/utils";
import { AppState, State } from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Cookies from "js-cookie";
import { AppMutation, AppActions } from "../types";
import { pingServer } from "@/api";

const state: AppState = {
  device: {
    isMobile: isMobile(),
    isIOS: isIosMobile(),
    orientation: getDeviceOrientation(),
  },
  soundState: parseInt(getCookieValue(CookiesToken.SOUND_STATE) ?? "1"),
  videoState: parseInt(getCookieValue(CookiesToken.VIDEO_STATE) ?? "1"),
  userInteraction: 0,
  isFullScreen: false,
  showLoading: false,
  line: parseInt(getCookieValue(CookiesToken.LINE) ?? "0"),
  lineStr: getCookieValue(CookiesToken.LINE_STR)?.decodeFromB64() ?? "",
  lang: getCookieValue(CookiesToken.LANG) || "zh",
};

const mutations: MutationTree<AppState> = {
  [AppMutionsType.SET_IS_MOBILE](state, payload) {
    state.device.isMobile = payload;
  },
  [AppMutation.SET_SOUND_STATE](state, payload: number) {
    state.soundState = payload;
    setCookieValue(CookiesToken.SOUND_STATE, String(payload));
  },
  [AppMutation.SET_VIDEO_STATE](state, payload: number) {
    state.videoState = payload;
    setCookieValue(CookiesToken.VIDEO_STATE, String(payload));
  },
  [AppMutation.SET_USER_INTERACTION](state) {
    state.userInteraction = 1;
  },
  [AppMutation.SHOW_LOADING](state) {
    state.showLoading = true;
  },
  [AppMutation.HIDE_LOADING](state) {
    state.showLoading = false;
  },
  [AppMutation.APP_SET_LINE](state, payload: number) {
    state.line = payload;
    setCookieValue(CookiesToken.LINE, String(state.line));
  },
  [AppMutation.APP_SET_LINE_STR](state, payload: string) {
    state.lineStr = payload;

    setCookieValue(CookiesToken.LINE_STR, payload.encodeToB64());
  },
  [AppMutation.SET_ORIENTATION](state, payload: "portrait" | "landscape") {
    state.device.orientation = payload;
  },
  [AppMutation.SET_FULL_SCREEN](state, payload: boolean) {
    state.isFullScreen = payload;
  },
  [AppMutation.SET_LANG](state, payload: string) {
    state.lang = payload;
    setCookieValue(CookiesToken.LANG, payload);
  },
};
const actions: ActionTree<AppState, State> = {
  [AppActions.PING_SERVER](
    { commit },
    params: { line: number; url: string }
  ): Promise<unknown> {
    return new Promise<unknown>((resolve, reject) => {
      const { line, url } = params;
      pingServer(line, url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const getters: GetterTree<AppState, State> = {
  isMobile(state): boolean {
    return state.device.isMobile;
  },
  isLoading(state): boolean {
    return state.showLoading;
  },
  serverLine(state): number {
    return state.line;
  },
  isFull(state): boolean {
    return state.isFullScreen;
  },
  audioIsOn(state): number {
    return state.soundState;
  },
  videoIsOn(state): number {
    return state.videoState;
  },
  userInteraction(state) {
    return state.userInteraction;
  },
  isIOS(state) {
    return state.device.isIOS;
  },
  deviceOrientation(state) {
    return state.device.orientation;
  },
  lang(state) {
    return state.lang;
  },
};

export const app: Module<AppState, State> = {
  state,
  mutations,
  actions,
  getters,
};
