import Cookies from "js-cookie";
import { createI18n } from "vue-i18n";
import CookiesToken from "./types/CookiesToken";
import { getBrowserLanguage, getCookieValue } from "./utils";

const messages = {
  en: {
    lines: {
      line1: "Line 1",
      line2: "Line 2",
      line3: "Line 3",
      enter: "Enter",
      custom_line: "Custom line",
      routeselection: "Route Selection",
      invalidip: "Invalid IP",
      p1: "Green",
      p2: "Click to enter directly, Red",
      p3: "It means that the line is unavailable,",
      p4: "If none of the three lines are available, please contact online customer service for more lines, and enter the line address obtained and click to enter, You can play, I wish you a happy game.",
    },
    lang: {
      zh: "简体中文",
      en: "English",
      kor: "한국어",
    },
    login: {
      username: "Account",
      password: "Password",
      verify: "Code",
      login: "Login",
      pleaseenterusername: "Please Enter Username",
      pleaseenterpwd: "Please Enter Password",
      invalidcode: "Invalid Code",
    },
    dialog: {
      language: "Language Settings",
    },
    accounthistory: "Act History",
    gamerules: "Game Rules",
    changepwd: "Change Pwd",
    language: "Language",
    fullscreen: "Full Screen",
    exitfullscreen: "Exit F.S",
    quit: "Logout",
    baccarat: "Baccarat",
    dragontiger: "Dragon Tiger",
    dealfirst: "Deal First",
    min: "Min Bet",
    max: "Max Bet",
    pairmin: "",
    pairmax: "P Max Bet",
    tiemax: "T Max Bet",
    singlelimit: "Single Limit",
    enter: "Enter",
    account: "Act",
    desk: "Table No.",
    balance: "Bal",
    loby: "Loby",
    stationlimit: "Station Limit",
    gameid: "Game ID",
    stopbetting: "Stop Betting",
    startbetting: "Start Betting",
    totalbet: "Total Bet",
    totalwin: "Total Win",
    confirm: "Confrim",
    cancel: "Cancel",
    panaromic: "Panaromic",
    banker: "B",
    player: "P",
    tie: "Tie",
    playerpair: "P.Pair",
    bankerpair: "B.Pair",
    dragon: "D",
    tiger: "T",
    custom: "Custom",
    bankerabt: "B",
    playerabt: "P",
    dragonabt: "D",
    tigerabt: "T",
    tieabt: "T",
    norecordfound: "There are no records to display.",
    startdate: "Start Date",
    enddate: "End Date",
    inquire: "Go",
    date: "Date",
    transactionno: "Transaction No.",
    totalvote: "Total Vote",
    twin: "Total Win",
    details: "Details",
    summary: "Summary",
    wascodebetting: "W.C Betting",
    codewashingfee: "C.W Fee",
    recorddetail: "Record Detail",
    bettingrecord: "Betting Record",
    gameno: "G. ID",
    bettingtable: "Betting Table",
    gameresult: "Game Result",
    typeofbet: "Type Of Bet",
    betamount: "Bet Amount",
    winlose: "Win/Lose",
    bettime: "Bet Time",
    oldpwd: "Old Pwd",
    newpwd: "New Pwd",
    confirmnewpwd: "Confirm New Pwd",
    cutomerservice: "C.Service",
    id: "ID",
    dragontigerdesk: "D.Tiger",
    dealfirstdesk: "V I P",
    ask: ".ASK",
    finish: "Done",
    bac: "B<br/>A<br/>C",
    dra: "D<br/>T",
    df: "DLF",
    stpbet: "STOP<br/>BET",
  },
  zh: {
    lines: {
      line1: "线路一",
      line2: "线路二",
      line3: "线路三",
      enter: "进入",
      custom_line: "自定义线路",
      routeselection: "线路选择",
      invalidip: "ip 地址错误",
      p1: "线路为绿色",
      p2: "可直接点击进入，红色",
      p3: "代表该线路不可用，",
      p4: "如三个线路均不可用，请联系在线客服获取更多线路，并输入所获取的线路地址点击进入，即可游戏，祝您游戏愉快。",
    },
    lang: {
      zh: "简体中文",
      en: "English",
      kor: "한국어",
    },
    login: {
      username: "账号",
      password: "密码",
      verify: "验证码",
      login: "登录",
      pleaseenterusername: "请输入用户名",
      pleaseenterpwd: "请输入密码",
      invalidcode: "验证码不正确",
    },
    dialog: {
      language: "语言设置",
    },
    accounthistory: "账户历史",
    gamerules: "游戏说明",
    changepwd: "修改密码",
    language: "语言设置",
    fullscreen: "全屏",
    exitfullscreen: "全屏",
    quit: "退出",
    baccarat: "百家乐",
    dragontiger: "龙虎",
    dealfirst: "先发牌区",
    min: "下限",
    max: "上限",
    pairmin: "",
    pairmax: "对子上限",
    tiemax: "和注上限",
    singlelimit: "单限",
    enter: "进入游戏",
    account: "账号",
    desk: "台号",
    balance: "余额",
    loby: "返回大厅",
    stationlimit: "本台单限",
    gameid: "局数",
    stopbetting: "停止投注",
    startbetting: "开始接受投注",
    totalbet: "下注金额",
    totalwin: "赢得金额",
    confirm: "确定",
    cancel: "重设",
    panaromic: "全景",
    banker: "庄",
    player: "閒",
    tie: "和",
    playerpair: "閒对",
    bankerpair: "庄对",
    dragon: "龍",
    tiger: "虎",
    custom: "自定义",
    bankerabt: "庄",
    playerabt: "閒",
    dragonabt: "龍",
    tigerabt: "虎",
    tieabt: "和",
    norecordfound: "没有要显示的记录",
    startdate: "开始日期",
    enddate: "结束日期",
    inquire: "查询",
    date: "日期",
    transactionno: "笔数",
    totalvote: "总投",
    twin: "总赢",
    details: "详情",
    summary: "小结",
    wascodebetting: "洗码费投注",
    codewashingfee: "洗码费",
    recorddetail: "详情记录",
    bettingrecord: "投注记录",
    gameno: "游戏编号",
    bettingtable: "投注桌台",
    gameresult: "游戏结果",
    typeofbet: "投注类型",
    betamount: "投注金额",
    winlose: "输赢",
    bettime: "投注时间",
    oldpwd: "原密码",
    newpwd: "新密码",
    confirmnewpwd: "确认新密码",
    cutomerservice: "联系客服",
    id: "局",
    dragontigerdesk: "龙虎",
    dealfirstdesk: "先发牌",
    ask: "问路",
    finish: "完成",
    bac: "百<br/>家<br/>乐",
    dra: "龙<br/>虎",
    df: "D L F",
    stpbet: "停止<br/>投注",
  },
  kor: {
    lines: {
      line1: "1행",
      line2: "2행",
      line3: "3행",
      enter: "입력하다",
      custom_line: "커스텀 라인",
      routeselection: "경로 선택",
      invalidip: "IP 주소 오류",
      p1: "라인은 녹색",
      p2: "직접 입력하려면 클릭, 빨간색",
      p3: "회선을 사용할 수 없음을 의미하며,",
      p4: "3개의 회선 중 아무 것도 사용할 수 없는 경우 온라인 고객 서비스에 연락하여 추가 회선을 확인하고 얻은 회선 주소를 입력하고 클릭하여 입력하십시오., 당신은 재생할 수 있습니다, 나는 당신에게 행복한 게임을 기원합니다.",
    },
    lang: {
      zh: "简体中文",
      en: "English",
      kor: "한국어",
    },
    login: {
      username: "계정",
      password: "비밀번호",
      verify: "암호",
      login: "로그인",
      pleaseenterusername: "사용자 이름을 입력하십시오",
      pleaseenterpwd: "비밀번호를 입력해주세요",
      invalidcode: "잘못된 인증 코드",
    },
    dialog: {
      language: "언어 설정",
    },
    accounthistory: "계정 기록",
    gamerules: "게임 규칙",
    changepwd: "비밀번호 변경",
    language: "언어",
    fullscreen: "전체 화면",
    exitfullscreen: "전체 화면",
    quit: "로그아웃",
    baccarat: "바카라",
    dragontiger: "용호",
    dealfirst: "선 딜링 테이블",
    min: "최소 베팅",
    max: "최대 베팅",
    pairmin: "",
    pairmax: "페어 맥스",
    tiemax: "타이 맥스",
    singlelimit: "단일 제한",
    enter: "시작하다",
    account: "계정",
    desk: "테이블 번호",
    balance: "잔액",
    loby: "로비",
    stationlimit: "스테이션 제한",
    gameid: "게임 아이디",
    stopbetting: "스톱베팅",
    startbetting: "시작 베팅",
    totalbet: "총 베팅",
    totalwin: "총 승리",
    confirm: "확인하다",
    cancel: "취소",
    panaromic: "파노라마",
    banker: "B",
    player: "P",
    tie: "Tie",
    playerpair: "P.Pair",
    bankerpair: "B.Pair",
    dragon: "D",
    tiger: "T",
    custom: "관습",
    bankerabt: "B",
    playerabt: "P",
    dragonabt: "D",
    tigerabt: "T",
    tieabt: "T",
    norecordfound: "표시할 기록이 없습니다.",
    startdate: "시작일",
    enddate: "종료일",
    inquire: "문의",
    date: "날짜",
    transactionno: "거래 번호",
    totalvote: "총 투표수",
    twin: "총 승리",
    details: "세부",
    summary: "요약",
    wascodebetting: "총 롤링",
    codewashingfee: "롤링",
    recorddetail: "레코드 세부 정보",
    bettingrecord: "베팅 기록",
    gameno: "게임 번호",
    bettingtable: "베팅 테이블",
    gameresult: "게임 결과",
    typeofbet: "베팅 유형",
    betamount: "배팅 금액",
    winlose: "승/패",
    bettime: "배팅 시간",
    oldpwd: "기존 비밀번호",
    newpwd: "새 비밀번호",
    confirmnewpwd: "새 비밀번호 확인",
    cutomerservice: "고객 서비스",
    id: "ID",
    dragontigerdesk: "용호",
    dealfirstdesk: "V I P",
    ask: ".물어보기",
    finish: "마치다",
    bac: "바<br/>카<br/>라",
    dra: "용<br/>호",
    df: "DLF",
    stpbet: "베팅<br/>중지",
  },
};
const i18n = createI18n({
  legacy: false,
  locale: getCookieValue(CookiesToken.LANG) || "zh",
  fallbackLocale: "zh",
  globalInjection: true,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  messages: messages,
});

export default i18n;
